@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

#login .view {
  background-image: url("../../../src/assets/images/alhambra-spain.webp");
  background-size: cover;
  height: 100vh;
}

#login .card {
  /* background-color: rgba(229, 228, 255, 0.2); */
  background-color: white;
}
#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0!important;
}
#login .md-form label {
  /* color: #ffffff; */
  color: black;
}
#login .md-form input[type=text]:focus:not([readonly])+label,
#login .md-form input[type=password]:focus:not([readonly])+label,
#login .md-form input[type=email]:focus:not([readonly])+label {
  /* color: #8EDEF8; */
  color: black;
}
#login .md-form input[type=text]:focus:not([readonly]),
#login .md-form input[type=password]:focus:not([readonly]),
#login .md-form input[type=email]:focus:not([readonly]) {
  /* border-bottom: 1px solid #8EDEF8;
  box-shadow: 0 1px 0 0 #8EDEF8; */
  border-bottom: 1px solid white;
  box-shadow: 0 1px 0 0 white;
}

#login .gradient-tsl {
  background: linear-gradient(40deg, #FBDD40, #008EAA, #008EAA);
}

#login .header-login {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: left;
  margin-top: -3.13rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 0.125rem;
}

#login .logo-login {
  width: 20%;
  display: inline-block;
}

#login .text-login {
  width: 80%;
  margin-bottom: 0;
  display: inline-block;
  padding-left: 2rem;
  vertical-align: middle;
}