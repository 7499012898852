.head{
  font-size: 1rem;
}
.row{
  font-size: .9rem;
}
.table a{
  color:rgb(48, 139, 218)!important
}
.table tbody tr:nth-of-type(odd) {
  background-color:#FAFAFA
}
.table thead th{
  border-bottom: 1px solid #dee2e6;
}