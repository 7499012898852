body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*--------------------------------------------------------------
 * Style for override Side Navigation
 --------------------------------------------------------------*/
div.logo-wrapper img {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
}

.side-nav .collapsible a {
  font-size: 0.95rem !important;
}

/*--------------------------------------------------------------
 * Style for override input elements
 --------------------------------------------------------------*/
.compact .md-form {
  margin-bottom: 0 !important;
}

/*--------------------------------------------------------------
 * Style for override select input elements
 --------------------------------------------------------------*/
input#selectSearchInput {
  width: 96%;
}

/*--------------------------------------------------------------
 * Style for error input
 --------------------------------------------------------------*/
.invalid-feedback { color: #f44336 !important; }
.form-control.is-invalid ~ .invalid-feedback {
  font-size: 80%;
  font-weight: 400;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #f44336 !important;
}

/*--------------------------------------------------------------
 * Style for error select input
 --------------------------------------------------------------*/
.select-wrapper.is-invalid {
  margin-bottom: -.6rem;
}

.select-wrapper.is-invalid .select-dropdown {
  border-color: #f44336;
  padding-right: 0;
}

.select-wrapper.is-invalid .select-dropdown ~ .invalid-feedback {
  display: block;
}

.select-wrapper.is-invalid span.caret {
  color: #f44336;
}

.react-datetime-picker__wrapper{
  border: none!important
}
.react-datetime-picker{
  border: none!important;
  border-bottom:1px #CCC solid!important;
}

/*--------------------------------------------------------------
 * CKEditor custom style
 --------------------------------------------------------------*/
.ck-editor__editable {
  min-height: 250px;
}
.ck-editor__label {
  margin-bottom: -1.6rem !important;
  margin-top: -1rem !important;
}
.ck-editor.invalid-feedback {
  margin-top: 2rem !important;
  margin-bottom: -1rem !important;
}

/*--------------------------------------------------------------
 * Datatable custom style
 *-------------------------------------------------------------*/
th.datatable-action_column {
  width: 6rem;
}

h4.datatable__title {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.dataTables_wrapper.datatable__w-98 {
  width: 98% !important;
}

.dataTables_wrapper .dataTables_length .select-wrapper {
  width: 4rem;
}

.dataTables_wrapper .dataTables_length .select-wrapper .select-dropdown {
  width: 100%;
}

div.dataTables_filter > input[type="search"] {
  width: 80% !important;
}

@media (max-width: 767px) {
  div.dataTables_filter {
    margin-top: 0 !important;
  }
}

div.data-table select {
  display: block !important;
}

div.data-table__filter-input .md-form {
  margin-top: .5rem;
  margin-bottom: 0 !important;
}

div.data-table__filter-input .md-form .search-input {
  padding-top: .5rem;
}

div.rdt_TableCell div:first-child {
  white-space: pre-line;
}

.rdt_TableCol input[type='checkbox'],
.rdt_TableCell input[type='checkbox'][name^='select-row-'] {
  opacity: 1 !important;
}

ul.list-on-datatable { margin: initial; }
ul.list-on-datatable li { margin-left: -1.5rem; }

table.table.expandable-table {
  margin-left: 4rem;
  vertical-align: top;
}

table.table.expandable-table td a {
  color: #007bff;
}

table.table.expandable-table td a:hover {
  color: #007bff;
  text-decoration: underline;
}
/*--------------------------------------------------------------
 * Modal Custom style
 *-------------------------------------------------------------*/
div.modal.fade {
  background-color: rgba(150, 150, 150, 0.5);;
}

.dropdown-content.select-dropdown .md-form,
.dropdown-content.select-dropdown .md-form #selectSearchInput,
.dropdown-content.select-dropdown .md-form .active{
  padding:5px 10px 5px 10px
}

/*--------------------------------------------------------------
 * Fixed button custom style
 *-------------------------------------------------------------*/
div.fixed-btn-group.btn-item-2 .fixed-action-btn.active ul {
  height: 10rem;
}

a.no-li + ul.list-unstyled {
  display: none !important;
}

/*--------------------------------------------------------------
 * MDBCollapse Header style
 *-------------------------------------------------------------*/
.card-header.is-invalid {
  background-color: #f44336 !important;
  color: white;
}

.compact .card-header {
  padding: .5rem 1rem;
}

.compact .card-header h5 {
  font-size: 1rem;
}

/*--------------------------------------------------------------
 * MDBTab custom style
 *-------------------------------------------------------------*/
.md-tabs .nav-link.inactive-nav-link {
  background-color: rgba(0, 0, 0, 0) !important;
}

/*--------------------------------------------------------------
 * MDBSelect custom style
 *-------------------------------------------------------------*/
.select-dropdown div.md-form {
  margin-bottom: .25rem;
}
.select-dropdown li[data-multiple=false].disabled,
.select-dropdown li[data-multiple=true] [type=checkbox]:disabled:not(:checked) + label:before {
  display: none;
}
.select-dropdown li[data-multiple=true].disabled label[data-multiple=true] {
  padding-left: 0;
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  pointer-events: all !important;
}

.rdt_TableHeadRow [type="checkbox"],
.rdt_TableRow [type="checkbox"] {
  width: 20px;
  height: 20px;
}

/*--------------------------------------------------------------
 * Modal custom style
 *-------------------------------------------------------------*/
.modal-filter-criteria div.md-form {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

/*--------------------------------------------------------------
 * React Player custom style
 *-------------------------------------------------------------*/
div.player.audio-type {
  height: 2rem !important;
}

table.table.expandable-table{
  /* margin-left:0px;
  padding-left:4rem; */
}
.react-datetime-picker__calendar {
  z-index: 4!important
}

ul.dropdown-content.select-dropdown {
  max-height: 200px;
  overflow:auto;
}